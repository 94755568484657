.u0wPu0Veldu3bw5QarsO {
    padding: 32px;
    background: var(--color-gradient-white);
    border-radius: 16px;
    margin: 90px 0 110px;
}


.lc8Ah6tj4tHWoYrLMlR3{
    margin: 0;
}

.wZCjDXLAL_W4aLxDyRo2{
    padding: 32px 0;
}

.U33XdfG647wJbpktWQpR {
    color: var(--color-graphite-2000);
    font-family: var(--font-headline-light-3-family);
    font-weight: var(--font-headline-light-3-weight);
    font-size: var(--font-headline-light-3-size);
    line-height: var(--font-headline-light-3-height);
    margin-bottom: 32px;
}

.ItUOe7N52finDFyPrt8w {
    display: block;
    color: var(--color-graphite-200);
    font-family: var(--font-title-regular-2-family);
    font-weight: var(--font-title-regular-2-weight);
    font-size: var(--font-title-regular-2-size);
    line-height: var(--font-title-regular-2-height);
    margin-top: -20px;
}

.EJTUQjTafoGYVGvu52Rt {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.VYzSChW226cg0JvqXoxA {
    margin-top: 40px;
    display: flex;
    align-items: center;
    width: 45%;
    flex-grow: 1;
    margin-right: 10%;
}

.VYzSChW226cg0JvqXoxA:nth-of-type(2n) {
    margin-right: 0;
}

.iyonWExc8p9UxdfnhLPL {
    width: 140px;
    height: 140px;
    border-radius: 16px;
    margin-right: 24px;
}

.xrfth7rkUqqjAdf31aC_ {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.tpDjLou9FLMQI6FhzRiG {
    font-family: var(--font-title-regular-2-family);
    font-weight: var(--font-title-regular-2-weight);
    font-size: var(--font-title-regular-2-size);
    line-height: var(--font-title-regular-2-height);
    color: var(--color-graphite-2000);
}

.nm0ZzAjw59YrKTu731qe {
    font-family: var(--font-body-light-2-family);
    font-weight: var(--font-body-light-2-weight);
    font-size: var(--font-body-light-2-size);
    line-height: var(--font-body-light-2-height);
    color: var(--color-graphite-2000);
}

.Kb6ogpiraGrEgb0uDXJD {
    margin-top: 20px;
    display: flex;
    gap: 36px;
    flex-wrap: wrap;
}

.Kb6ogpiraGrEgb0uDXJD a {
    text-decoration: none;
    font-family: var(--font-body-regular-2-family);
    font-weight: var(--font-body-regular-2-weight);
    font-size: var(--font-body-regular-2-size);
    line-height: var(--font-body-regular-2-height);
    color: var(--color-graphite-2000);
    display: flex;
    align-items: center;
}

.Kb6ogpiraGrEgb0uDXJD a img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.bxVjapcIWaA11ex4isDl {
    display: flex;
}

.dJEiktgKM9cPUVloHXJs{
   margin-top: 32px;
}

.ow60mnzLuwp_hapAgUx8 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc((100% - 80px) / 3);
    margin-right: 40px;
}

.IqYI8AwuzRZyWHElJ8EO{
    width: 100%;
}

.ow60mnzLuwp_hapAgUx8:last-of-type {
    margin-right: 0;
}

.HXBYWa035eyhNBJ79yRG {
    font-family: var(--font-title-regular-2-family);
    font-weight: var(--font-title-regular-2-weight);
    font-size: var(--font-title-regular-2-size);
    line-height: var(--font-title-regular-2-height);
    color: var(--color-graphite-2000);
    text-decoration: none;
    margin-bottom: 4px;
}

.gmuA8xK9OB2UciYkymYS{
    font-family: var(--font-title-regular-2-family);
    font-weight: var(--font-title-regular-2-weight);
    font-size: var(--font-title-regular-2-size);
    line-height: var(--font-title-regular-2-height);
    color: var(--color-graphite-2000);
    text-decoration: none;
    margin-bottom: 4px;
}

.QPp52K3_JVXAGSNyffqJ {
    font-family: var(--font-body-light-2-family);
    font-weight: var(--font-body-light-2-weight);
    font-size: var(--font-body-light-2-size);
    line-height: var(--font-body-light-2-height);
    color: var(--color-graphite-1100);
    margin-top: 4px;
}

.QPp52K3_JVXAGSNyffqJ a {
    text-decoration: underline;
    font-family: var(--font-body-light-2-family);
    font-weight: var(--font-body-light-2-weight);
    font-size: var(--font-body-light-2-size);
    line-height: var(--font-body-light-2-height);
    color: var(--color-graphite-1100);
  }

  .oDkNDRaQQ0lR18OkCDgF{
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
}

.RtIJxf0hPrJVCKGch0AA{
    margin: 0;
}

._85oT39szvBuLmfwZMj_X{
    text-decoration: underline;
}

.AQckILVLJVRqxpw0bcjA{
    width: 100%;
    background: transparent;
    position: absolute;
    top: -90px;
}

@media screen and (max-width: 1300px) {
    .Kb6ogpiraGrEgb0uDXJD {
        flex-direction: column;
        gap: 16px;
    }
}

@media screen and (max-width: 1024px) {
    .u0wPu0Veldu3bw5QarsO {
        padding: 32px;
    }

    .bxVjapcIWaA11ex4isDl {
        flex-wrap: wrap;
    }

    .ow60mnzLuwp_hapAgUx8 {
        margin-right: 0;
        width: 90%;
        margin-bottom: 24px;
    }

    .dJEiktgKM9cPUVloHXJs{
        margin-top: 24px;
     }

    .ow60mnzLuwp_hapAgUx8:last-of-type {
        margin-bottom: 0;
    }

    .U33XdfG647wJbpktWQpR {
        font-family: var(--font-body-light-1-family);
        font-weight: var(--font-body-light-1-weight);
        font-size: var(--font-body-light-1-size);
        line-height: var(--font-body-light-1-height);
        margin-bottom: 20px;
    }

    .ItUOe7N52finDFyPrt8w {
        font-family: var(--font-body-regular-2-family);
        font-weight: var(--font-body-regular-2-weight);
        font-size: var(--font-body-regular-2-size);
        line-height: var(--font-body-regular-2-height);
    }

    .QPp52K3_JVXAGSNyffqJ {
        color: var(--color-graphite-1100);
    }

    .EJTUQjTafoGYVGvu52Rt {
        flex-direction: column;
    }

    .VYzSChW226cg0JvqXoxA {
        margin-bottom: 40px;
        width: 100%;
    }

    .oDkNDRaQQ0lR18OkCDgF{
        display: flex;
        flex-flow: row wrap;
        gap: 0;
        justify-content: space-between;
    }

    .RtIJxf0hPrJVCKGch0AA{
        width: 40%;
    }

    .lc8Ah6tj4tHWoYrLMlR3{
        margin: 0;
    }
}

@media screen and (max-width: 768px) {
    .u0wPu0Veldu3bw5QarsO {
        margin: 40px 0 64px;
        padding: 32px 20px;
    }

    .oDkNDRaQQ0lR18OkCDgF{
        flex-direction: column;
        gap: 0;
    }

    .RtIJxf0hPrJVCKGch0AA{
        width: 100%;

    }

    .lc8Ah6tj4tHWoYrLMlR3{
        margin: 0;
    }
}

@media screen and (max-width: 500px) {
    .u0wPu0Veldu3bw5QarsO {
        margin: 36px 0 40px;
    }

    .U33XdfG647wJbpktWQpR {
        margin-bottom: 24px;
        font-family: var(--font-title-regular-2-family);
        font-weight: var(--font-title-regular-2-weight);
        font-size: var(--font-title-regular-2-size);
        line-height: var(--font-title-regular-2-height);
    }

    .ow60mnzLuwp_hapAgUx8 {
        margin-bottom: 20px;
    }

    .dJEiktgKM9cPUVloHXJs{
        margin-top: 20px;
     }

    .HXBYWa035eyhNBJ79yRG {
        font-family: var(--font-body-regular-2-family);
        font-weight: var(--font-body-regular-2-weight);
        font-size: var(--font-body-regular-2-size);
        line-height: var(--font-body-regular-2-height);
    }

    .gmuA8xK9OB2UciYkymYS{
        font-family: var(--font-body-regular-2-family);
        font-weight: var(--font-body-regular-2-weight);
        font-size: var(--font-body-regular-2-size);
        line-height: var(--font-body-regular-2-height);
        text-decoration: underline;
    }

    .QPp52K3_JVXAGSNyffqJ {
        font-family: var(--font-body-light-3-family);
        font-weight: var(--font-body-light-3-weight);
        font-size: var(--font-body-light-3-size);
        line-height: var(--font-body-light-3-height);
    }

    .VYzSChW226cg0JvqXoxA {
        flex-direction: column;
        align-items: flex-start;
    }

    .iyonWExc8p9UxdfnhLPL {
        height: 128px;
        width: 128px;
        margin-bottom: 24px;
        margin-right: 0;
    }

    .tpDjLou9FLMQI6FhzRiG {
        font-family: var(--font-body-regular-2-family);
        font-weight: var(--font-body-regular-2-weight);
        font-size: var(--font-body-regular-2-size);
        line-height: var(--font-body-regular-2-height);
        margin-bottom: 4px;
    }

    .nm0ZzAjw59YrKTu731qe {
        font-family: var(--font-body-light-3-family);
        font-weight: var(--font-body-light-3-weight);
        font-size: var(--font-body-light-3-size);
        line-height: var(--font-body-light-3-height);
    }

    .Kb6ogpiraGrEgb0uDXJD {
        flex-flow: row wrap;
    }

    .Kb6ogpiraGrEgb0uDXJD a {
        font-family: var(--font-body-regular-3-family);
        font-weight: var(--font-body-regular-3-weight);
        font-size: var(--font-body-regular-3-size);
        line-height: var(--font-body-regular-3-height);
    }

    .Kb6ogpiraGrEgb0uDXJD a[href^='tel'] {
        text-decoration: underline;
    }

    .QPp52K3_JVXAGSNyffqJ a {
        text-decoration: underline;
        font-family: var(--font-body-light-3-family);
        font-weight: var(--font-body-light-3-weight);
        font-size: var(--font-body-light-3-size);
        line-height: var(--font-body-light-3-height);
        color: var(--color-graphite-1100);
      }
}

.E62ezBWjW08pxZfiaV0e {
    position: relative;
    z-index: 1;
    background: #283038;
    padding: 40px 0;
    flex-grow: 1;
}

.LRbjTWWSggWnQ4RNwDOO {
    padding-bottom: 20px;
    padding-top: 36px;
}

.e9asBwBE_wWQyVm4Taqr {
    max-width: 1500px;
    width: calc(100% - 420px);
    margin: 0 auto;
}

.zXC07P9X4C34g0vxGcgZ {
    border-bottom: 0.5px solid var(--color-graphite-200);
    height: 0;
    width: 100%;
}

.oRL_HP5hN2PrethztyMV {
    display: none;
}

.kjQQD7UqW20I7BUTEJJD {
    padding: 0;
}

.LRbjTWWSggWnQ4RNwDOO .kjQQD7UqW20I7BUTEJJD {
    border: none;
}

.kjQQD7UqW20I7BUTEJJD:first-of-type {
    border-top: none;
}

.WBd0jyvSE_12xM2cB_SB {
    display: flex;
}

.fU4i0GJBFxxpcuXyxLzS {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.SjmOlCGO7jLDQpyHyOS8 {
    width: fit-content;
    margin-right: 80px;
    padding: 12px 0;
}

.SjmOlCGO7jLDQpyHyOS8:nth-of-type(4n) {
    margin-right: 0;
}

.lTyc7JudqbyhXWttge2h {
    font-family: var(--font-title-regular-2-family);
    font-weight: var(--font-title-regular-2-weight);
    font-size: var(--font-title-regular-2-size);
    line-height: var(--font-title-regular-2-height);
    color: var(--color-white);
    text-decoration: none;
}

.lTyc7JudqbyhXWttge2h:hover {
    text-decoration: underline;
    color: var(--color-confetti-600);
}

.j9bLeFWCu5titjtg9Qi2 {
    display: flex;
}

.Ir044pXN0LgVndnJK69P {
    margin-right: 130px;
}

.fCcCDyJlGk0czR_0aDew {
    list-style: none;
    width: 320px;
    margin: 0 130px 0 0;
    padding: 0;
}

.fCcCDyJlGk0czR_0aDew:last-of-type {
    margin-right: 0;
}

.nmojkqfbB4L6Fuq6YGpi {
    margin-bottom: 24px;
}

.vaRtZz3iGl7E_2ZORpPQ {
    font-family: var(--font-title-regular-2-family);
    font-weight: var(--font-title-regular-2-weight);
    font-size: var(--font-title-regular-2-size);
    line-height: var(--font-title-regular-2-height);
    color: var(--color-white);
    text-decoration: none;
}

.xDiIXYCpFSVF70GeoKwb {
    padding: 0 4px;
    width: 100%;
}

.XyIt_nL1QU4JJbmEJFj_ {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    width: 100%;
}

.UtZyUrgrvRXGSmlCvjz7 {
    text-align: left;
    width: 33%;
    padding: 12px 0;
}

.GO3HtvD4_y8IHvej2KP4 {
    font-family: var(--font-body-regular-3-family);
    font-weight: var(--font-body-regular-3-weight);
    font-size: var(--font-body-regular-3-size);
    line-height: var(--font-body-regular-3-height);
    color: var(--color-gray-1-5);
    text-decoration: none;
}

.GO3HtvD4_y8IHvej2KP4:hover {
    color: var(--color-confetti-600);
    text-decoration: underline;
}

.c2eV5mtxEYm8dSDOGUzg {
    font-family: var(--font-body-medium-3-family);
    font-weight: var(--font-body-medium-3-weight);
    font-size: var(--font-body-medium-3-size);
    line-height: var(--font-body-medium-3-height);
    color: var(--color-white);
    display: block;
    margin-bottom: 4px;
}

.c2eV5mtxEYm8dSDOGUzg a[href^='tel'] {
    white-space: nowrap;
}

.kQefEbvyaEdWcJOKkAfi {
    margin-bottom: 24px;
}

.aTv22b0uLjrR1XKD0_py {
    white-space: nowrap;
}

.rgeHpxGpkjLvBPbvxzVw:hover {
    color: var(--color-confetti-600);
    text-decoration: underline;
}

.c2eV5mtxEYm8dSDOGUzg a {
    color: inherit;
    text-decoration: none;
}

.bBQMY1v2mVPFn05Ln7qx {
    font-family: var(--font-label-light-1-family);
    font-weight: var(--font-label-light-1-weight);
    font-size: var(--font-label-light-1-size);
    line-height: var(--font-label-light-1-height);
    letter-spacing: -0.2px;
    color: var(--color-gray-1-5);
    margin-bottom: 24px;
}

.bBQMY1v2mVPFn05Ln7qx:last-child,
.vsDuoJRzPXATiexNPvRE {
    margin-bottom: 0;
}

.CQqdxBCA7o5Sk4ymp1nc {
    font-family: var(--font-body-medium-2-family);
    font-weight: var(--font-body-medium-2-weight);
    font-size: var(--font-body-medium-2-size);
    line-height: var(--font-body-medium-2-height);
    letter-spacing: -0.02em;
    color: var(--color-white);
    margin: 0 0 20px;
}

.bBQMY1v2mVPFn05Ln7qx a {
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 3px;
}

.bBQMY1v2mVPFn05Ln7qx a[href^='tel'] {
    white-space: nowrap;
}

.csnAmk2BPn7RIuDVv9q7 {
    display: flex;
    justify-content: space-between;
}

.kjQQD7UqW20I7BUTEJJD:last-child {
    padding-top: 12px;
}

.kjQQD7UqW20I7BUTEJJD.VZgWTdL798oZILEmXNqZ {
    padding: 0;
}

.kjQQD7UqW20I7BUTEJJD.PDAn6xyQaJ8Lv2LCVnqy {
    padding: 12px 0 20px;
}

.FMI2cPycP8yYV0cWwUs4 {
    width: calc(50% - 18px);
}

.HOqr1FCe2h4PX8mgA1Yx {
    display: flex;
    gap: 36px;
}

.HOqr1FCe2h4PX8mgA1Yx ul {
    flex-grow: 1;
    margin: 0;
    list-style: none;
    padding: 0;
}
.CTdtiaI3zdEzqMA255lI {
    display: inline-block;
    height: 100%;
    width: auto;
}
.HOqr1FCe2h4PX8mgA1Yx li {
    font-family: var(--font-label-light-1-family);
    font-weight: var(--font-label-light-1-weight);
    font-size: var(--font-label-light-1-size);
    line-height: var(--font-label-light-1-height);
    letter-spacing: -0.02em;
    color: var(--color-iron-gray);
    margin-bottom: 24px;
}

.HOqr1FCe2h4PX8mgA1Yx li a {
    color: inherit;
    text-decoration: none;
    padding-bottom: 1px;
    border-bottom: 1px solid var(--color-graphite-200);
}

.HOqr1FCe2h4PX8mgA1Yx li:last-of-type {
    margin-bottom: 0;
}

@media screen and (max-width: 1536px) {
    .e9asBwBE_wWQyVm4Taqr {
        width: calc(100% - 240px);
    }
}


@media screen and (max-width: 1210px) {
    .XyIt_nL1QU4JJbmEJFj_ {
        flex-wrap: wrap;
    }

    .UtZyUrgrvRXGSmlCvjz7 {
        margin-right: 28px;
    }

    .UtZyUrgrvRXGSmlCvjz7:last-child {
        margin-right: 0;
    }
}

@media screen and (max-width: 1154px) {
    .XyIt_nL1QU4JJbmEJFj_ {
        justify-content: flex-start;
    }

    .UtZyUrgrvRXGSmlCvjz7 {
        margin-right: 28px;
    }

    .UtZyUrgrvRXGSmlCvjz7:last-child {
        margin-top: 20px;
    }
}

@media screen and (max-width: 1024px) {
    .E62ezBWjW08pxZfiaV0e {
        padding: 28px 0;
    }

    .e9asBwBE_wWQyVm4Taqr {
        width: calc(100% - 120px);
    }

    .Ir044pXN0LgVndnJK69P {
        margin-right: 32px;
    }

    .fCcCDyJlGk0czR_0aDew {
        width: 220px;
        margin-right: 32px;
    }

    .vaRtZz3iGl7E_2ZORpPQ {
        font-size: var(--font-body-regular-2-size);
        line-height: var(--font-body-regular-2-height);
    }

    .XyIt_nL1QU4JJbmEJFj_ {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .UtZyUrgrvRXGSmlCvjz7,
    .UtZyUrgrvRXGSmlCvjz7:last-child {
        width: fit-content;
        margin: 0 28px 20px 0;
        text-align: left;
    }

    .SjmOlCGO7jLDQpyHyOS8 {
        margin-right: 64px;
    }
}

@media screen and (max-width: 850px) {
    .e9asBwBE_wWQyVm4Taqr {
        width: calc(100% - 80px);
    }
}

@media screen and (max-width: 768px) {
    .kjQQD7UqW20I7BUTEJJD:nth-child(5) {
        padding-top: 12px;
    }

    .HOqr1FCe2h4PX8mgA1Yx {
        flex-direction: column;
        gap: 24px;
    }

    .SjmOlCGO7jLDQpyHyOS8 {
        margin-right: 24px;
        width: calc((100% - 72px) / 4);
        max-width: 300px;

    }

    .lTyc7JudqbyhXWttge2h {
        font-family: var(--font-body-regular-3-family);
        font-weight: var(--font-body-regular-3-weight);
        font-size: var(--font-body-regular-3-size);
        line-height: var(--font-body-regular-3-height);
        color: var(--color-white);
        text-decoration: underline;
    }

    .GO3HtvD4_y8IHvej2KP4 {
        text-decoration: underline;
        line-height: 18px;
    }

    .c2eV5mtxEYm8dSDOGUzg a {
        text-decoration: underline;
    }

    .c2eV5mtxEYm8dSDOGUzg {
        font-family: var(--font-body-regular-3-family);
        font-weight: var(--font-body-regular-3-weight);
        font-size: var(--font-body-regular-3-size);
        line-height: var(--font-body-regular-3-height);
    }

    .rB4CPNASEmwZSZ_x6VJj {
        font-family: var(--font-body-regular-3-family);
        font-weight: var(--font-body-regular-3-weight);
        font-size: var(--font-body-regular-3-size);
        line-height: var(--font-body-regular-3-height);
    }

    .csnAmk2BPn7RIuDVv9q7 {
        flex-direction: column;
    }

    .FMI2cPycP8yYV0cWwUs4 {
        width: 100%;
        margin-bottom: 24px;
    }

    .FMI2cPycP8yYV0cWwUs4:last-child {
        margin-bottom: 0;
    }

    .sLfNsIPxiXRyl3iipfcw {
        margin: 0;
    }

    .FMI2cPycP8yYV0cWwUs4:last-of-type {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 500px) {
    .E62ezBWjW08pxZfiaV0e {
        padding: 20px 0;
    }

    .e9asBwBE_wWQyVm4Taqr {
        width: calc(100% - 40px);
    }

    .LRbjTWWSggWnQ4RNwDOO {
        padding: 40px 0;
    }

    .zXC07P9X4C34g0vxGcgZ {
        margin: 0;
    }

    .HOqr1FCe2h4PX8mgA1Yx {
        padding-bottom: 36px;
        padding-top: 36px;
    }

    .CQqdxBCA7o5Sk4ymp1nc {
        font-size: var(--font-body-regular-3-size);
        line-height: var(--font-body-regular-3-height);
    }

    .bBQMY1v2mVPFn05Ln7qx {
        color: var(--color-gray-1-5);
    }

    .kjQQD7UqW20I7BUTEJJD.VZgWTdL798oZILEmXNqZ {
        padding: 36px 0;
    }

    .c2eV5mtxEYm8dSDOGUzg {
        font-family: var(--font-body-regular-3-family);
        font-weight: var(--font-body-regular-3-weight);
        font-size: var(--font-body-regular-3-size);
        line-height: var(--font-body-regular-3-height);
    }

    .c2eV5mtxEYm8dSDOGUzg.rB4CPNASEmwZSZ_x6VJj {
        font-family: var(--font-body-medium-3-family);
        font-weight: var(--font-body-medium-3-weight);
        font-size: var(--font-body-medium-3-size);
        line-height: var(--font-body-medium-3-height);
        margin-bottom: 4px;
    }

    .c2eV5mtxEYm8dSDOGUzg.f3PHhoSRD3yKALQQXSZb {
        margin-bottom: 0;
    }

    .kQefEbvyaEdWcJOKkAfi {
        font-family: var(--font-label-light-1-family);
        font-weight: var(--font-label-light-1-weight);
        font-size: var(--font-label-light-1-size);
        line-height: var(--font-label-light-1-height);
        margin-bottom: 12px;
    }

    .Ir044pXN0LgVndnJK69P {
        display: none;
    }

    .oRL_HP5hN2PrethztyMV {
        display: block;
    }

    .j9bLeFWCu5titjtg9Qi2 {
        flex-direction: column;
    }

    .nmojkqfbB4L6Fuq6YGpi.LDJdFVuo5pgGs6UeiQUL {
        margin-bottom: 0;
    }

    .UtZyUrgrvRXGSmlCvjz7,
    .UtZyUrgrvRXGSmlCvjz7:last-child {
        margin: 0 0 16px;
    }

    .fU4i0GJBFxxpcuXyxLzS {
        flex-direction: column;
    }

    .SjmOlCGO7jLDQpyHyOS8 {
        width: 100%;
        margin-right: 0;
    }

    .kjQQD7UqW20I7BUTEJJD:last-child {
        padding-top: 12px;
        padding-bottom: 0;
    }
}

@media print {
    .E62ezBWjW08pxZfiaV0e {
        display: none;
    }
}

.OH9JgzxLDuL6txSHWA6e {
    background: transparent;
}

._vVcSgL4GPH_6seNjbG0 {
    display: none;
}

@media screen and (max-width: 500px) {
    .OH9JgzxLDuL6txSHWA6e {
        display: none;
    }

    ._vVcSgL4GPH_6seNjbG0 {
        display: block;
    }
    
}

.pJG6oKSmscGpi4wdQYDt {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-left: 388px;
}

.QKlH1AKh882B6FUtBf2x {
    margin-top: 40px;
    display: inline-flex;
    color: #566a7a;
}

.QKlH1AKh882B6FUtBf2x:first-of-type {
    margin-top: 0;
}

.rKLr4uqDDgW7pQygvcRM {
    flex-shrink: 0;
    width: 724px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    font-family: var(--font-headline-light-2-family);
    font-size: var(--font-headline-light-2-size);
    line-height: var(--font-headline-light-2-height);
    font-weight: var(--font-headline-light-2-weight);
    color: #566a7a;
}

.MDDcE6Rjq4aHjUUpETYU {
    width: 20px;
    height: 20px;
    fill: #566a7a;
    margin-left: 10px;
}

.MDDcE6Rjq4aHjUUpETYU.Ihghpw132U6Y6lltAj7o {
    position: relative;
    left: 8px;
    width: 23px;
    height: 14px;
    display: none;
}

.hpEjHtXgj76pc4RlhtqZ {
    pointer-events: none;
    color: var(--color-graphite-2000);
}

.hpEjHtXgj76pc4RlhtqZ .MDDcE6Rjq4aHjUUpETYU {
    fill: var(--color-graphite-2000);
}

.rKLr4uqDDgW7pQygvcRM:visited {
    color: inherit;
}

.rKLr4uqDDgW7pQygvcRM:hover {
    color: var(--color-graphite-2000);
}

.rKLr4uqDDgW7pQygvcRM:hover .MDDcE6Rjq4aHjUUpETYU {
    display: none;
}

.rKLr4uqDDgW7pQygvcRM:hover .MDDcE6Rjq4aHjUUpETYU.Ihghpw132U6Y6lltAj7o {
    display: unset;
    fill: var(--color-graphite-2000);
}

@media screen and (max-width: 1280px) {
    .pJG6oKSmscGpi4wdQYDt {
        margin-left: 320px;
    }

    .rKLr4uqDDgW7pQygvcRM {
        width: 400px;
        font-family: var(--font-headline-light-3-family);
        font-size: var(--font-headline-light-3-size);
        line-height: var(--font-headline-light-3-height);
        font-weight: var(--font-headline-light-3-weight);
    }
}

@media screen and (max-width: 1024px) {
    .pJG6oKSmscGpi4wdQYDt {
        margin-left: 277px;
    }

    .rKLr4uqDDgW7pQygvcRM {
        width: 392px;
    }
}

@media screen and (max-width: 768px) {
    .pJG6oKSmscGpi4wdQYDt {
        margin-left: 177px;
    }

    .rKLr4uqDDgW7pQygvcRM {
        width: 393px;
    }
}

@media screen and (max-width: 650px) {
    .pJG6oKSmscGpi4wdQYDt {
        margin-left: 0;
        width: 100%;
    }

    .QKlH1AKh882B6FUtBf2x {
        margin-top: 44px;
        display: inline-flex;
        width: 100%;
    }

    .rKLr4uqDDgW7pQygvcRM {
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .rKLr4uqDDgW7pQygvcRM {
        font-family: var(--font-body-light-1-family);
        font-size: var(--font-body-light-1-size);
        line-height: var(--font-body-light-1-height);
        font-weight: var(--font-body-light-1-weight);
    }
}
.df43y0SpmwSenLYLluDf {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1500px;
    height: calc(100% - 72px);
}

.oUoIhPRtN_iokey3oNPt {
    flex-shrink: 0;
    width: 100%;
}

@media screen and (max-width: 600px) {
    .df43y0SpmwSenLYLluDf {
        height: calc(100% - 60px);
    }
}
@keyframes aXlIQNmqgEpmjYetjpHv {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

/* --- primary */

/* normal size */
.kqIMoOq2MM1NyGiiiuyG {
    position: relative;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    color: var(--color-white);
    background: var(--color-graphite-1150);
    border-radius: 12px;
    min-width: 48px;
    min-height: 48px;
    max-height: 48px;
    text-decoration: none;
    border: none;
    outline: none;
}

.kqIMoOq2MM1NyGiiiuyG.RqDw6Qzhe1T_h1dOxNlQ {
    pointer-events: none;
    user-select: none;
    color: transparent;
    background: var(--color-iron-gray);
}

.kqIMoOq2MM1NyGiiiuyG.RqDw6Qzhe1T_h1dOxNlQ > * {
    user-select: none;
    opacity: 0;
}

.kqIMoOq2MM1NyGiiiuyG.RqDw6Qzhe1T_h1dOxNlQ::before {
    box-sizing: border-box;
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    border: 3px solid var(--color-graphite-900);
    border-top: 3px solid var(--color-white);
    opacity: 1;
    content: '';
    background: transparent;
    animation: aXlIQNmqgEpmjYetjpHv 1.5s infinite;
    pointer-events: none;
}

.kqIMoOq2MM1NyGiiiuyG.Q1ffyVXUq8pVlEzdlEfK {
    padding: 12px;
    max-width: 48px;
}

.kqIMoOq2MM1NyGiiiuyG:hover {
    background: var(--color-graphite-2000);
}

.kqIMoOq2MM1NyGiiiuyG:active {
    background: var(--color-graphite-900);
}

.kqIMoOq2MM1NyGiiiuyG:disabled,
.kqIMoOq2MM1NyGiiiuyG.rGLOS7TRJJiRelFOg9Ph {
    pointer-events: none;
    color: var(--color-white);
    background: var(--color-iron-gray);
}

.kqIMoOq2MM1NyGiiiuyG:focus {
    box-shadow: inset 0 0 0 1.5px var(--color-confetti-1000);
}

.kqIMoOq2MM1NyGiiiuyG:focus:not(:focus-visible) {
    box-shadow: none;
}

.a7tI_wj3uahQ2zTnZfsW {
    order: 2;
    font-family: var(--font-body-regular-2-family);
    font-size: var(--font-body-regular-2-size);
    line-height: var(--font-body-regular-2-height);
    font-weight: var(--font-body-regular-2-weight);
}

.kwdJ_2aCeASRX81gBt8W .a7tI_wj3uahQ2zTnZfsW {
    color: var(--color-white);
}

.kqIMoOq2MM1NyGiiiuyG svg {
    flex-shrink: 0;
    order: 3;
    fill: var(--color-white);
    width: 24px;
    height: 24px;
    margin-left: 12px;
}

.EWm68nGEsX_N_uD7xoqo svg {
    fill: var(--color-graphite);
}

.kqIMoOq2MM1NyGiiiuyG.aXcqckQcILPtuIGTzUIQ svg {
    order: 1;
    margin-left: 0;
    margin-right: 12px;
}

.kqIMoOq2MM1NyGiiiuyG.Q1ffyVXUq8pVlEzdlEfK svg {
    margin: 0;
}

.EWm68nGEsX_N_uD7xoqo {
    background: var(--color-iron-gray);
}

.EWm68nGEsX_N_uD7xoqo .a7tI_wj3uahQ2zTnZfsW {
    color: var(--color-graphite);
}

.EWm68nGEsX_N_uD7xoqo:hover {
    background: var(--color-gray-1-5);
}

.kwdJ_2aCeASRX81gBt8W .a7tI_wj3uahQ2zTnZfsW {
    color: var(--color-white);
}

/* small size */
.kqIMoOq2MM1NyGiiiuyG.JxaWMGJCrMjokB0qmNQZ {
    padding: 8px 16px;
    border-radius: 9px;
    min-width: 36px;
    min-height: 36px;
    max-height: 36px;
}

.JxaWMGJCrMjokB0qmNQZ.Q1ffyVXUq8pVlEzdlEfK {
    max-width: 36px;
    padding: 8px;
}

.JxaWMGJCrMjokB0qmNQZ .a7tI_wj3uahQ2zTnZfsW {
    font-family: var(--font-body-medium-3-family);
    font-size: var(--font-body-medium-3-size);
    line-height: var(--font-body-medium-3-height);
    font-weight: var(--font-body-medium-3-weight);
}

.kqIMoOq2MM1NyGiiiuyG.JxaWMGJCrMjokB0qmNQZ svg {
    transform: scale(0.8335);
    margin-left: 8px;
}

.kqIMoOq2MM1NyGiiiuyG.qDZwk5FTWBuaON6OquX4 svg {
    margin-left: 0;
}

@media screen and (max-width: 768px) {
    .kqIMoOq2MM1NyGiiiuyG.NTxfO9bsvm3nOKgNCDyG {
        padding: 8px 16px;
        border-radius: 9px;
        min-width: 36px;
        min-height: 36px;
        max-height: 36px;
    }

    .kqIMoOq2MM1NyGiiiuyG.NTxfO9bsvm3nOKgNCDyG.Q1ffyVXUq8pVlEzdlEfK {
        max-width: 36px;
        padding: 8px;
    }

    .kqIMoOq2MM1NyGiiiuyG.NTxfO9bsvm3nOKgNCDyG .a7tI_wj3uahQ2zTnZfsW {
        font-family: var(--font-body-medium-3-family);
        font-size: var(--font-body-medium-3-size);
        line-height: var(--font-body-medium-3-height);
        font-weight: var(--font-body-medium-3-weight);
    }

    .kqIMoOq2MM1NyGiiiuyG.NTxfO9bsvm3nOKgNCDyG svg {
        transform: scale(0.8335);
        margin-left: 8px;
    }
}

@media screen and (max-width: 500px) {
    .kqIMoOq2MM1NyGiiiuyG.Z65lj2TIBvcOeazL2w_N {
        padding: 8px 16px;
        border-radius: 9px;
        min-width: 36px;
        min-height: 36px;
        max-height: 36px;
    }

    .kqIMoOq2MM1NyGiiiuyG.Z65lj2TIBvcOeazL2w_N.Q1ffyVXUq8pVlEzdlEfK {
        max-width: 36px;
        padding: 8px;
    }

    .kqIMoOq2MM1NyGiiiuyG.Z65lj2TIBvcOeazL2w_N .a7tI_wj3uahQ2zTnZfsW {
        font-family: var(--font-body-medium-3-family);
        font-size: var(--font-body-medium-3-size);
        line-height: var(--font-body-medium-3-height);
        font-weight: var(--font-body-medium-3-weight);
    }

    .kqIMoOq2MM1NyGiiiuyG.Z65lj2TIBvcOeazL2w_N svg {
        transform: scale(0.8335);
        margin-left: 8px;
    }
}

/* unbordered */
.kqIMoOq2MM1NyGiiiuyG.WMZ8mWMCI9b2fyEZJYE7 {
    color: var(--color-graphite-2000);
    background: var(--color-iron-gray);
}

.kqIMoOq2MM1NyGiiiuyG.WMZ8mWMCI9b2fyEZJYE7.RqDw6Qzhe1T_h1dOxNlQ {
    background: var(--color-light-gray);
}

.kqIMoOq2MM1NyGiiiuyG.WMZ8mWMCI9b2fyEZJYE7:hover {
    color: var(--color-graphite-1150);
    background: var(--color-gray-1-5);
}

.kqIMoOq2MM1NyGiiiuyG.WMZ8mWMCI9b2fyEZJYE7:active {
    color: var(--color-graphite-900);
    background: var(--color-cool-gray-0);
}

.kqIMoOq2MM1NyGiiiuyG.WMZ8mWMCI9b2fyEZJYE7:disabled,
.kqIMoOq2MM1NyGiiiuyG.WMZ8mWMCI9b2fyEZJYE7.rGLOS7TRJJiRelFOg9Ph {
    pointer-events: none;
    color: var(--color-cool-gray-3);
    background: var(--color-cool-gray-0);
}

.kqIMoOq2MM1NyGiiiuyG.WMZ8mWMCI9b2fyEZJYE7 svg {
    fill: var(--color-graphite-2000);
}

.kqIMoOq2MM1NyGiiiuyG.c2px7WRjMHp2bxllokN4 svg {
    fill: var(--color-graphite-2000);
}

.kqIMoOq2MM1NyGiiiuyG.WMZ8mWMCI9b2fyEZJYE7:hover svg {
    fill: var(--color-graphite-1150);
}

.kqIMoOq2MM1NyGiiiuyG.WMZ8mWMCI9b2fyEZJYE7:active svg {
    fill: var(--color-graphite-900);
}

.kqIMoOq2MM1NyGiiiuyG.WMZ8mWMCI9b2fyEZJYE7:disabled svg,
.kqIMoOq2MM1NyGiiiuyG.WMZ8mWMCI9b2fyEZJYE7.rGLOS7TRJJiRelFOg9Ph svg {
    fill: var(--color-cool-gray-3);
}

/* transparent */
.kqIMoOq2MM1NyGiiiuyG.c2px7WRjMHp2bxllokN4 {
    color: var(--color-graphite-2000);
    background: transparent;
}

.kqIMoOq2MM1NyGiiiuyG.kqIMoOq2MM1NyGiiiuyG.c2px7WRjMHp2bxllokN4.RqDw6Qzhe1T_h1dOxNlQ {
    background: transparent;
}

.kqIMoOq2MM1NyGiiiuyG.c2px7WRjMHp2bxllokN4:hover {
    color: var(--color-confetti-800);
    background: transparent;
}

.kqIMoOq2MM1NyGiiiuyG.c2px7WRjMHp2bxllokN4.QzIGyqtFTl5dRO_zWuKR:hover {
    color: var(--color-graphite-2000);
}

.kqIMoOq2MM1NyGiiiuyG.c2px7WRjMHp2bxllokN4:active {
    color: var(--color-confetti-600);
    background: transparent;
}

.kqIMoOq2MM1NyGiiiuyG.c2px7WRjMHp2bxllokN4.QzIGyqtFTl5dRO_zWuKR:active {
    color: var(--color-graphite-2000);
}

.kqIMoOq2MM1NyGiiiuyG.c2px7WRjMHp2bxllokN4:disabled,
.kqIMoOq2MM1NyGiiiuyG.c2px7WRjMHp2bxllokN4.rGLOS7TRJJiRelFOg9Ph {
    pointer-events: none;
    color: var(--color-cool-gray-3);
    background: transparent;
}

.kqIMoOq2MM1NyGiiiuyG.c2px7WRjMHp2bxllokN4:hover svg {
    fill: var(--color-confetti-950);
}

.kqIMoOq2MM1NyGiiiuyG.c2px7WRjMHp2bxllokN4:active svg {
    fill: var(--color-confetti);
}

.kqIMoOq2MM1NyGiiiuyG.c2px7WRjMHp2bxllokN4:disabled svg,
.kqIMoOq2MM1NyGiiiuyG.c2px7WRjMHp2bxllokN4.rGLOS7TRJJiRelFOg9Ph svg {
    fill: var(--color-cool-gray-3);
}

.kqIMoOq2MM1NyGiiiuyG.c2px7WRjMHp2bxllokN4.QzIGyqtFTl5dRO_zWuKR:hover svg,
.kqIMoOq2MM1NyGiiiuyG.c2px7WRjMHp2bxllokN4.QzIGyqtFTl5dRO_zWuKR:active svg {
    color: var(--color-graphite-2000);
}

.kqIMoOq2MM1NyGiiiuyG.qqDY2y0iDyeYoaj_oLqg {
    padding-left: 0;
    padding-right: 0;
}

/* loader */
.kqIMoOq2MM1NyGiiiuyG.JxaWMGJCrMjokB0qmNQZ.RqDw6Qzhe1T_h1dOxNlQ::before {
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    width: 20px;
    height: 20px;
    border-radius: 20px;
}

@media screen and (max-width: 768px) {
    .kqIMoOq2MM1NyGiiiuyG.NTxfO9bsvm3nOKgNCDyG.RqDw6Qzhe1T_h1dOxNlQ::before {
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        width: 20px;
        height: 20px;
        border-radius: 20px;
    }
}

@media screen and (max-width: 500px) {
    .kqIMoOq2MM1NyGiiiuyG.Z65lj2TIBvcOeazL2w_N.RqDw6Qzhe1T_h1dOxNlQ::before {
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        width: 20px;
        height: 20px;
        border-radius: 20px;
    }
}

.ApOOVAi72XXn7tuRjTQA{
    padding: 16px 0px;
    display: flex;
    margin-right: 56px;
}

.rtdaVdI4zqzz92mOJ38O{
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.i6HiSrvqvK9QXuFSDv5B:first-of-type,
.Ay5Xh5ZLxF8wlq2rQ8HS:first-of-type{
    margin-right:32px;
    font-family: var(--font-body-medium-2-family);
    font-size: var(--font-body-medium-2-size);
    font-weight: var(--font-body-medium-2-weight);
    line-height: var(--font-body-medium-2-height);
}
.i6HiSrvqvK9QXuFSDv5B:first-of-type::after,
.Ay5Xh5ZLxF8wlq2rQ8HS:first-of-type::after{
    content: "";
    position: relative;
    height: 24px;
    border-right: 1px var(--color-gray-1-5) solid;
    left: 16px;
    border-radius: 4px;
}


.i6HiSrvqvK9QXuFSDv5B{
    font-family: var(--font-body-medium-2-family);
    font-size: var(--font-body-medium-2-size);
    font-weight: var(--font-body-medium-2-weight);
    line-height: var(--font-body-medium-2-height);
    color: var(--color-graphite-2000);
}

.Ay5Xh5ZLxF8wlq2rQ8HS{
    font-family: var(--font-body-medium-2-family);
    font-size: var(--font-body-medium-2-size);
    font-weight: var(--font-body-medium-2-weight);
    line-height: var(--font-body-medium-2-height);
    color: var(--color-graphite-550);
}

@media screen and (max-width: 800px){
    .ApOOVAi72XXn7tuRjTQA{
        margin-right: 36px;
    }
}
@media screen and (max-width: 650px){
    .ApOOVAi72XXn7tuRjTQA{
        padding: 0px;
        margin-right: 0px;
    }
}
.PkEsELjQbrv3BxHj65jT {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 96px;
    max-height: 100%;
    overflow: hidden;
    background-color: transparent;
    transition: top 300ms, opacity 600ms;
}

.PkEsELjQbrv3BxHj65jT.spcx2ZrS4F7TO05BEiqf {
    box-shadow: 0 0 16px rgb(51 63 72 / 4%);
    background-color: var(--color-background-gray);
    transition: top 300ms, background-color 800ms;
}

.PkEsELjQbrv3BxHj65jT.po1YjKC17NYA3hOawK1Q {
    height: 100%;
    box-shadow: 0 0 16px rgb(51 63 72 / 4%);
    background: #edeeef;
    overflow-y: auto;
    transition: top 300ms;
}

.container.XB3mR7ku942QPvMYnaAj {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    max-width: 1500px;
    height: 96px;
}

.iGTI5T3gmnx2fof7yd7g,
.EMBeWbwOtPIySE81hH_u {
    display: flex;
    height: 100%;
    align-items: center;
}

.Ob1MC1PHAd7mr4H_iNfG {
    display: none;
}

.mUfLnYeWv2_4vm_TlxrL {
    position: relative;
    flex-shrink: 0;
    width: 216px;
}

.olRzDmPFEztbwON9Oi_Q {
    display: none;
    flex-shrink: 0;
    width: 114px;
}

.txHJnnA57RO7LW7GkKC6 {
    display: none;
    flex-shrink: 0;
    width: 140px;
}

.jbRqpDRDn9eQUkul4LcB {
    flex-shrink: 0;
    width: 100%;
    height: auto;
    max-width: 100%;
}

.Zx9ZrC18SuysyQzdCd7T {
    margin-left: 172px;
    display: flex;
    align-items: center;
}

.Zx9ZrC18SuysyQzdCd7T:focus-visible {
    border: 1px solid var(--color-confetti-1000);
    border-radius: 11px;
}

.MjWOzYCsCt6e7xsBZkzM {
    display: none;
}

.yDknQSRk14HGZScIU_YH {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: auto;
}

.yDknQSRk14HGZScIU_YH img {
    flex-shrink: 0;
    max-width: 100%;
}

.SWV8mJlk7haya6c93ML9 {
    flex-shrink: 0;
    margin-left: 10px;
    font-family: var(--font-body-regular-2-family);
    font-weight: var(--font-body-regular-2-weight);
    font-size: var(--font-body-regular-2-size);
    line-height: var(--font-body-regular-2-height);
    color: var(--color-graphite-2000);
}

.ZkBM_FitcwYyzxUJADyG {
    display: none;
}

.wmYhzLDRWDBJ7ZxBE0Ni{
    display: none;
}


@media screen and (max-width: 1280px) {
    .Zx9ZrC18SuysyQzdCd7T {
        margin-left: 104px;
    }
}

@media screen and (max-width: 1024px) {
    .PkEsELjQbrv3BxHj65jT {
        height: 72px;
    }

    .container.XB3mR7ku942QPvMYnaAj {
        height: 72px;
    }

    .Zx9ZrC18SuysyQzdCd7T {
        margin-left: 60px;
    }

    .zFgis5TYuias5fBEPev0 {
        padding: 8px 16px;
        border-radius: 9px;
        min-width: 36px;
        min-height: 36px;
        max-height: 36px;
    }

    .zFgis5TYuias5fBEPev0 span {
        font-family: var(--font-body-medium-3-family);
        font-size: var(--font-body-medium-3-size);
        line-height: var(--font-body-medium-3-height);
        font-weight: var(--font-body-medium-3-weight);
    }
}

@media screen and (max-width: 768px) {
    .mUfLnYeWv2_4vm_TlxrL {
        display: none
    }

    .olRzDmPFEztbwON9Oi_Q {
        display: block;
    }
}





@media screen and (max-width: 650px) {
    .PkEsELjQbrv3BxHj65jT {
        height: 60px;
    }

    .container.XB3mR7ku942QPvMYnaAj {
        height: 60px;
        flex-wrap: nowrap;
    }

    .iGTI5T3gmnx2fof7yd7g {
        display: none;
    }

    .Ob1MC1PHAd7mr4H_iNfG {
        display: flex;
        height: 100%;
        align-items: center;
    }

    .olRzDmPFEztbwON9Oi_Q {
        display: none;
    }

    .txHJnnA57RO7LW7GkKC6 {
        display: block;
    }

    .Zx9ZrC18SuysyQzdCd7T {
        display: none;
    }

    .MjWOzYCsCt6e7xsBZkzM {
        display: flex;
        align-items: center;
        padding: 0;
        margin-left: 20px;
    }

    .ZBKFlPWnmR1bEIOSpzCp {
        display: inline-flex;
    }

    .po1YjKC17NYA3hOawK1Q .ZBKFlPWnmR1bEIOSpzCp {
        display: none;
    }

    .po1YjKC17NYA3hOawK1Q .ZkBM_FitcwYyzxUJADyG {
        position: absolute;
        top: 64px;
        left: 20px;
        display: inline-flex;
        width: calc(100% - 40px);
    }

    .wmYhzLDRWDBJ7ZxBE0Ni{
        display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;
        top: 124px;
    }
}

@media screen and (min-width: 3000px) {
    .PkEsELjQbrv3BxHj65jT {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
        flex-wrap: nowrap;
    }
}

@media print {
    .PkEsELjQbrv3BxHj65jT {
        display: none;
    }
}

.djgBhah4k1KEGnH7gM9S {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(40 48 56 / 0%) 0%, #283038 85.61%);
    opacity: 0.3;
}
.FtpJEClH_6SmUbDIYHUe {
    background-color: var(--color-background-gray);
}

.KT6bVs2roQ0wYnxiQC86 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 194px;
}

.VayRLJdfyG9PvFxt_fQ_ {
    width: 400px;
    height: 400px;
    flex-shrink: 0;
}

.mAzRnASLlXeNgjuljvq3 {
    margin-top: -20px;
    text-align: center;
    font-family: var(--font-headline-semibold-3-family);
    font-weight: var(--font-headline-semibold-3-weight);
    font-size: var(--font-headline-semibold-3-size);
    line-height: var(--font-headline-semibold-3-height);
    letter-spacing: var(--font-headline-semibold-3-spacing);
}

.rLGooIfDrLgd2eGdp109 {
    max-width: 844px;
    margin-top: 14px;
    text-align: center;
    font-family: var(--font-paragraph-regular-family);
    font-weight: var(--font-paragraph-regular-weight);
    font-size: var(--font-paragraph-regular-size);
    line-height: var(--font-paragraph-regular-height);
    letter-spacing: var(--font-paragraph-regular-spacing);
}

@media screen and (max-width: 768px) {
    .KT6bVs2roQ0wYnxiQC86 {
        padding-top: 154px;
    }

    .rLGooIfDrLgd2eGdp109 {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 500px) {
    .KT6bVs2roQ0wYnxiQC86 {
        padding-top: 122px;
    }

    .VayRLJdfyG9PvFxt_fQ_ {
        width: 235px;
        height: 235px;
    }

    .mAzRnASLlXeNgjuljvq3 {
        text-align: center;
        font-family: var(--font-title-semibold-family);
        font-weight: var(--font-title-semibold-weight);
        font-size: var(--font-title-semibold-size);
        line-height: var(--font-title-semibold-height);
        letter-spacing: var(--font-title-semibold-spacing);
    }

    .rLGooIfDrLgd2eGdp109 {
        margin-bottom: 4px;
        font-family: var(--font-body-regular-old-2-family);
        font-weight: var(--font-body-regular-old-2-weight);
        font-size: var(--font-body-regular-old-2-size);
        line-height: var(--font-body-regular-old-2-height);
        letter-spacing: var(--font-body-regular-old-2-spacing);
    }
}

@font-face {
    font-family: SBSansDisplayLight;
    src: url("/fonts/light/SBSansDisplay-Light.woff"),
        url("/fonts/light/SBSansDisplay-Light.woff2");
}

@font-face {
    font-family: SBSansTextLight;
    src: url("/fonts/light/SBSansText-Light.woff"),
        url("/fonts/light/SBSansDisplay-Light.woff2");
}

@font-face {
    font-family: SBSansTextRegular;
    src: url("/fonts/regular/SBSansText-Regular.woff"),
        url("/fonts/regular/SBSansText-Regular.woff2");
}

@font-face {
    font-family: SBSansTextMedium;
    src: url("/fonts/medium/SBSansText-Medium.woff"),
        url("/fonts/medium/SBSansText-Medium.woff2");
}

/* legacy font-faces */
@font-face {
    font-family: SBSansText;
    font-weight: 300;
    src: url("/fonts/light/SBSansText-Light.woff"),
        url("/fonts/light/SBSansDisplay-Light.woff2");
}

@font-face {
    font-family: SBSansText;
    font-weight: 400;
    src: url("/fonts/regular/SBSansText-Regular.woff"),
        url("/fonts/regular/SBSansText-Regular.woff2");
}


@font-face {
    font-family: SBSansText;
    font-weight: 500;
    src: url("/fonts/medium/SBSansText-Medium.woff"),
        url("/fonts/medium/SBSansText-Medium.woff2");
}


@font-face {
    font-family: SBSansText;
    font-weight: 600;
    src: url("/fonts/semibold/SBSansText-SemiBold.woff"),
        url("/fonts/semibold/SBSansText-SemiBold.woff2");
}

@font-face {
    font-family: SBSansDisplay;
    font-weight: 300;
    src: url("/fonts/light/SBSansDisplay-Light.woff"),
        url("/fonts/light/SBSansDisplay-Light.woff2");
}

@font-face {
    font-family: SBSansDisplay;
    font-weight: 400;
    src: url("/fonts/medium/SBSansText-Medium.woff"),
        url("/fonts/medium/SBSansText-Medium.woff2");
}

@font-face {
    font-family: SBSansDisplay;
    font-weight: 600;
    src: url("/fonts/semibold/SBSansDisplay-SemiBold.woff"),
        url("/fonts/semibold/SBSansText-SemiBold.woff2");
}

@font-face {
    font-family: "SBSansTextItalic";
    font-weight: 400;
    src: url("/fonts/regular/SBSansText-RegularItalic.woff"),
    url("/fonts/regular/SBSansText-RegularItalic.woff2");
}

:root {
    /* headline-light-1 */
    --font-headline-light-1-family: 'SBSansDisplayLight', arial, helvetica, sans-serif;
    --font-headline-light-1-size: 74px;
    --font-headline-light-1-height: 89px;
    --font-headline-light-1-weight: 300;

    /* headline-light-2 */
    --font-headline-light-2-family: 'SBSansTextLight', arial, helvetica, sans-serif;
    --font-headline-light-2-size: 42px;
    --font-headline-light-2-height: 56px;
    --font-headline-light-2-weight: 300;

    /* headline-regular-2 */
    --font-headline-regular-2-family: 'SBSansTextRegular', arial, helvetica, sans-serif;
    --font-headline-regular-2-size: 42px;
    --font-headline-regular-2-height: 56px;
    --font-headline-regular-2-weight: 400;

    /* headline-light-3 */
    --font-headline-light-3-family: 'SBSansTextLight', arial, helvetica, sans-serif;
    --font-headline-light-3-size: 30px;
    --font-headline-light-3-height: 36px;
    --font-headline-light-3-weight: 300;

    /* headline-regular-3 */
    --font-headline-regular-3-family: 'SBSansTextRegular', arial, helvetica, sans-serif;
    --font-headline-regular-3-size: 30px;
    --font-headline-regular-3-height: 36px;
    --font-headline-regular-3-weight: 400;

    /* paragraph-light-1 */
    --font-paragraph-light-1-family: 'SBSansDisplayLight', arial, helvetica, sans-serif;
    --font-paragraph-light-1-size: 36px;
    --font-paragraph-light-1-height: 46px;
    --font-paragraph-light-1-weight: 300;

    /* title-light-1 */
    --font-title-light-1-family: 'SBSansTextLight', arial, helvetica, sans-serif;
    --font-title-light-1-size: 26px;
    --font-title-light-1-height: 32px;
    --font-title-light-1-weight: 300;

    /* title-light-2 */
    --font-title-light-2-family: 'SBSansTextLight', arial, helvetica, sans-serif;
    --font-title-light-2-size: 20px;
    --font-title-light-2-height: 26px;
    --font-title-light-2-weight: 300;

      /* title-regular-1 */
      --font-title-regular-1-family: 'SBSansTextRegular', arial, helvetica, sans-serif;
      --font-title-regular-1-size: 26px;
      --font-title-regular-1-height: 32px;
      --font-title-regular-1-weight: 400;
      --font-title-regular-1-spacing: 0%;

    /* title-regular-2 */
    --font-title-regular-2-family: 'SBSansTextRegular', arial, helvetica, sans-serif;
    --font-title-regular-2-size: 20px;
    --font-title-regular-2-height: 26px;
    --font-title-regular-2-weight: 400;

     /* title-medium-1 */
     --font-title-medium-1-family: 'SBSansTextMedium', arial, helvetica, sans-serif;
     --font-title-medium-1-size: 20px;
     --font-title-medium-1-height: 26px;
     --font-title-medium-1-weight: 500;

    /* title-medium-2 */
    --font-title-medium-2-family: 'SBSansTextMedium', arial, helvetica, sans-serif;
    --font-title-medium-2-size: 20px;
    --font-title-medium-2-height: 26px;
    --font-title-medium-2-weight: 600;

    /* title-medium-3 */
    --font-title-medium-3-family: 'SBSansTextMedium', arial, helvetica, sans-serif;
    --font-title-medium-3-size: 16px;
    --font-title-medium-3-height: 26px;
    --font-title-medium-3-weight: 600;

    /* title-medium-4 */
    --font-title-medium-4-family: 'SBSansTextMedium', arial, helvetica, sans-serif;
    --font-title-medium-4-size: 14px;
    --font-title-medium-4-height: 20px;
    --font-title-medium-4-weight: 500;

     /* title-medium-5 */
     --font-title-medium-5-family: 'SBSansTextMedium', arial, helvetica, sans-serif;
     --font-title-medium-5-size: 16px;
     --font-title-medium-5-height: 20px;
     --font-title-medium-5-weight: 500;


    /* body-light-1 */
    --font-body-light-1-family: 'SBSansTextLight', arial, helvetica, sans-serif;
    --font-body-light-1-size: 22px;
    --font-body-light-1-height: 32px;
    --font-body-light-1-weight: 300;

    /* body-regular-1 */
    --font-body-regular-1-family: 'SBSansTextRegular', arial, helvetica, sans-serif;
    --font-body-regular-1-size: 22px;
    --font-body-regular-1-height: 32px;
    --font-body-regular-1-weight: 400;

    /* body-light-2 */
    --font-body-light-2-family: 'SBSansTextLight', arial, helvetica, sans-serif;
    --font-body-light-2-size: 16px;
    --font-body-light-2-height: 24px;
    --font-body-light-2-weight: 300;

    /* body-regular-2 */
    --font-body-regular-2-family: 'SBSansTextRegular', arial, helvetica, sans-serif;
    --font-body-regular-2-size: 16px;
    --font-body-regular-2-height: 24px;
    --font-body-regular-2-weight: 400;

    /* body-medium-2 */
    --font-body-medium-2-family: 'SBSansTextMedium', arial, helvetica, sans-serif;
    --font-body-medium-2-size: 16px;
    --font-body-medium-2-height: 24px;
    --font-body-medium-2-weight: 500;


    /* body-light-3 */
    --font-body-light-3-family: 'SBSansTextLight', arial, helvetica, sans-serif;
    --font-body-light-3-size: 14px;
    --font-body-light-3-height: 20px;
    --font-body-light-3-weight: 300;

    /* body-regular-3 */
    --font-body-regular-3-family: 'SBSansTextRegular', arial, helvetica, sans-serif;
    --font-body-regular-3-size: 14px;
    --font-body-regular-3-height: 20px;
    --font-body-regular-3-weight: 400;

     /* body-regular-4 */
    --font-body-regular-4-family: 'SBSansTextRegular', arial, helvetica, sans-serif;
    --font-body-regular-4-size: 26px;
    --font-body-regular-4-height: 32px;
    --font-body-regular-4-weight: 400;
    --font-body-regular-4-spacing: 0%;

    /* body-regular-5 */
    --font-body-regular-5-family: 'SBSansTextRegular', arial, helvetica, sans-serif;
    --font-body-regular-5-size: 19px;
    --font-body-regular-5-height: 26px;
    --font-body-regular-5-weight: 400;
    --font-body-regular-5-spacing: 0%;

    /* body-medium-3 */
    --font-body-medium-3-family: 'SBSansTextMedium', arial, helvetica, sans-serif;
    --font-body-medium-3-size: 14px;
    --font-body-medium-3-height: 20px;
    --font-body-medium-3-weight: 500;


    /* label-light-1 */
    --font-label-light-1-family: 'SBSansTextLight', arial, helvetica, sans-serif;
    --font-label-light-1-size: 13px;
    --font-label-light-1-height: 16px;
    --font-label-light-1-weight: 300;

    /* label-light-2 */
    --font-label-light-2-family: 'SBSansTextLight', arial, helvetica, sans-serif;
    --font-label-light-2-size: 12px;
    --font-label-light-2-height: 16px;
    --font-label-light-2-weight: 300;

    /* label-regular-2 */
    --font-label-regular-2-family: 'SBSansTextRegular', arial, helvetica, sans-serif;
    --font-label-regular-2-size: 12px;
    --font-label-regular-2-height: 16px;
    --font-label-regular-2-weight: 500;

    /* quote-regular-1 */
    --font-quote-regular-1-family: 'SBSansTextItalic', italic, arial, helvetica, sans-serif;
    --font-quote-regular-1-size: 20px;
    --font-quote-regular-1-height: 30px;
    --font-quote-regular-1-weight: 400;

    /* quote-regular-2 */
    --font-quote-regular-2-family: 'SBSansTextItalic', italic, arial, helvetica, sans-serif;
    --font-quote-regular-2-size: 16px;
    --font-quote-regular-2-height: 24px;
    --font-quote-regular-2-weight: 400;

    /* paragraph-regular-2 */
    --font-paragraph-regular-2-family: 'SBSanDisplay', arial, helvetica, sans-serif;
    --font-paragraph-regular-2-size: 24px;
    --font-paragraph-regular-2-height: 40px;
    --font-paragraph-regular-2-weight: 400;

     /* title-semibold 2 */
     --font-title-semibold-2-family: 'SBSansText', arial, helvetica, sans-serif;
     --font-title-semibold-2-size: 18px;
     --font-title-semibold-2-height: 26px;
     --font-title-semibold-2-weight: 600;
     --font-title-semibold-2-spacing: -0.02em;

    /* title-semibold 3 */
    --font-title-semibold-3-family: 'SBSansDisplay', arial, helvetica, sans-serif;
    --font-title-semibold-3-size: 32px;
    --font-title-semibold-3-height: 40px;
    --font-title-semibold-3-weight: 600;
    --font-title-semibold-3-spacing: 0%;
    
    /* title-semibold-4 */
    --font-title-semibold-4-family: 'SBSansDisplay', arial, helvetica, sans-serif;
    --font-title-semibold-4-size: 42px;
    --font-title-semibold-4-height: 54px;
    --font-title-semibold-4-weight: 600;
    --font-title-semibold-4-spacing: 0%;

     /* caption-regular*/
     --font-caption-regular-family: 'SBSansText', arial, helvetica, sans-serif;
     --font-caption-regular-size: 12px;
     --font-caption-regular-height: 16px;
     --font-caption-regular-weight: 400;
     --font-caption-regular-spacing: -2%;

      /* link-medium-1 */
     --font-link-medium-1-family: 'SBSansText', arial, helvetica, sans-serif;
     --font-link-medium-1-size: 20px;
     --font-link-medium-1-height: 28px;
     --font-link-medium-1-weight: 500;
     --font-link-medium-1-spacing: -2%;

      /* link-medium-2 */
     --font-link-medium-2-family: 'SBSansText', arial, helvetica, sans-serif;
     --font-link-medium-2-size: 16px;
     --font-link-medium-2-height: 24px;
     --font-link-medium-2-weight: 500;
     --font-link-medium-2-spacing: -2%;

      /* headline-regular-4 */
     --font-headline-regular-4-family: 'SBSansText', arial, helvetica, sans-serif;
     --font-headline-regular-4-size: 24px;
     --font-headline-regular-4-height: 26px;
     --font-headline-regular-4-weight: 400;
     --font-headline-regular-4-spacing: 0%;
    /* legacy fonts */

    /* headline-semibold-2 */
    --font-headline-semibold-2-family: 'SBSansDisplay', arial, helvetica, sans-serif;
    --font-headline-semibold-2-size: 32px;
    --font-headline-semibold-2-height: 40px;
    --font-headline-semibold-2-weight: 600;
    --font-headline-semibold-2-spacing: -0.02em;

    /* headline-semibold-3 */
    --font-headline-semibold-3-family: 'SBSansDisplay', arial, helvetica, sans-serif;
    --font-headline-semibold-3-size: 24px;
    --font-headline-semibold-3-height: 28px;
    --font-headline-semibold-3-weight: 600;
    --font-headline-semibold-3-spacing: -0.02em;

    /* paragraph-regular */
    --font-paragraph-regular-family: 'SBSansText', arial, helvetica, sans-serif;
    --font-paragraph-regular-size: 24px;
    --font-paragraph-regular-height: 36px;
    --font-paragraph-regular-weight: 400;
    --font-paragraph-regular-spacing: -0.02em;

    /* title-semibold */
    --font-title-semibold-family: 'SBSansText', arial, helvetica, sans-serif;
    --font-title-semibold-size: 20px;
    --font-title-semibold-height: 26px;
    --font-title-semibold-weight: 600;
    --font-title-semibold-spacing: -0.02em;

    /* body-regular-2-old */
    --font-body-regular-old-2-family: 'SBSansText', arial, helvetica, sans-serif;
    --font-body-regular-old-2-size: 16px;
    --font-body-regular-old-2-height: 24px;
    --font-body-regular-old-2-weight: 400;
    --font-body-regular-old-2-spacing: -0.02em;

    /* body-semibold-2 */
    --font-body-semibold-2-family: 'SBSansText', arial, helvetica, sans-serif;
    --font-body-semibold-2-size: 16px;
    --font-body-semibold-2-height: 24px;
    --font-body-semibold-2-weight: 600;
}

:root {
    /* primary */
    --color-background-gray: #edeeef;
    --color-graphite-2000: #283038;
    --color-graphite-1150: #333f48;
    --color-graphite-1100: #485966;
    --color-graphite-1000: #566a7a;
    --color-graphite-900: #5d7384;
    --color-iron-gray: #dae0e6;
    --color-graphite-200: #939a9f;
    --color-main-graphite-200: rgb(218, 224, 230);
    --color-graphite-150: #94a6b3;
    --color-graphite-100: #edeeef;
    --color-graphite-550: rgb(148, 166, 179);
    --color-gray-3: #8c959c;
    --color-gray-6: #707070;
    --color-gray-1-5: #bec9d1;
    --color-cool-gray-0: #f2f5f7;
    --color-cool-gray-2: #8b8e98;
    --color-cool-gray-3: #8c959c;
    --color-cool-gray-6: #677178;
    --color-confetti-600: #dee157;
    --color-confetti-800: #afb220;
    --color-confetti-950: #a1a41d;
    --color-confetti-1000: #93951b;
    --color-confetti: #cbcf25;
    --color-iron-gray-300: #c5cfd6;
    --color-light-gray: #f5f6f7;
    --color-light-gray-0: #f8f9fa;
    --color-white: #fff;
    --color-carnation-300: #f9495b;
    --color-jungle-reen-300: #25a481;

    /* opacity */
    --color-white-opacity: rgb(255 255 255 / 50%);
    --color-iron-gray-opacity: rgb(237 240 242 / 70%);
    --color-white-opacity-70: rgb(255 255 255 / 90%);
    --color-graphite-opacity: rgb(51 63 72 / 70%);

    /* gradient */
    --color-gradient-white: linear-gradient(143.52deg, #fff 10.4%, #F7F8F9 95.97%);
}

html, body {
    width: 100%;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    /* scroll-behavior: smooth;
    scroll-padding-top: 90px ; */
}

* {
    box-sizing: border-box;
}

section {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    background: transparent;
    max-width: 1920px;
    overflow: visible;
    margin: 0 auto;
}

button {
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
}

h1, h2, h3, h4, h5 {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

ul {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
}

p {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

body.noscroll {
    position: fixed;
    height: 100%;
    overflow-y: overlay;
}

.page__blur {
    display: none;
}

body.noscroll .page__blur {
    display: block;
}

body.noscroll.noblur .page__blur {
    display: none;
}

header.header_disabled {
    opacity: 0.2;
}

.container {
    width: calc(100% - 420px);
}

.paragraph {
    font-family: var(--font-paragraph-light-1-family);
    font-weight: var(--font-paragraph-light-1-weight);
    font-size: var(--font-paragraph-light-1-size);
    line-height: var(--font-paragraph-light-1-height);
    color: var(--color-monolith-black);
}

@media screen and (max-width: 1536px) {
    .container {
        width: calc(100% - 240px);
    }
}

@media screen and (max-width: 1024px) {
    .container {
        width: calc(100% - 120px);
    }
}

@media screen and (max-width: 850px) {
    .container {
        width: calc(100% - 80px);
    }
}

@media screen and (max-width: 768px) {
    .paragraph {
        font-family: var(--font-body-light-1-family);
        font-weight: var(--font-body-light-1-weight);
        font-size: var(--font-body-light-1-size);
        line-height: var(--font-body-light-1-height);
    }
}

@media screen and (max-width: 500px) {
    .container {
        width: calc(100% - 40px);
    }

    .paragraph {
        font-family: var(--font-body-light-2-family);
        font-weight: var(--font-body-light-2-weight);
        font-size: var(--font-body-light-2-size);
        line-height: var(--font-body-light-2-height);
    }
}

@media print {
    .container {
        width: calc(100% - 40px) !important;
    }

    .no-print {
        display: none;
    }
}

.k7PXsG3i2JF9d5fMNK1T {
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--color-background-gray);
}

.B22GjG5qPWgfoUDkIm25 {
    display: inline-block;
    position: relative;
    width: 54px;
    height: 54px;
}

.B22GjG5qPWgfoUDkIm25 div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 43px;
    height: 43px;
    margin: 5px;
    border: 5px solid var(--color-graphite-900);
    border-radius: 50%;
    animation: cB12pJHAu3sw7QCIQVqj 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color-graphite-900) transparent transparent transparent;
}

.B22GjG5qPWgfoUDkIm25 div:nth-child(1) {
    animation-delay: -0.45s;
}

.B22GjG5qPWgfoUDkIm25 div:nth-child(2) {
    animation-delay: -0.3s;
}

.B22GjG5qPWgfoUDkIm25 div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes cB12pJHAu3sw7QCIQVqj {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

